<script>
import Product from '@maison/components/common/Product';
import SearchPanelSubmenuMixin from '@maison/mixins/searchPanelSubmenuMixin';
import { PhxIcon } from '@phx-styleguides/jlc';

export default {
  components: {
    Product,
    PhxIcon,
  },

  mixins: [SearchPanelSubmenuMixin],
};
</script>

<template>
  <div class="mega-menu__panel-col mega-menu__panel-col--expanded search-submenu-products">
    <section v-if="shouldDisplayNoResults" class="section section--text text-block text-block--centered">
      <h3 class="text-block__title h4">{{ noResultsText }}</h3>
    </section>

    <template v-else-if="hasItems">
      <div class="header-section">
        <h3 class="header-section__title h4">{{ submenuTitle }}</h3>
      </div>

      <div class="mixed-grid mixed-grid--tight search-submenu-products__desktop">
        <product
          v-for="product in items"
          :key="product.modelReference"
          :image="product.searchImg"
          v-bind="product"
          :display-classes="['product-card--small', 'product-card--horizontal']"
          :should-display-add-to-wishlist="false"
        />
      </div>

      <ul class="product-card-list search-submenu-products__mobile">
        <li v-for="product in items" :key="product.modelReference" class="product-card-list__entry">
          <product
            :image="product.searchImg"
            v-bind="product"
            :display-classes="['product-card--small', 'product-card--horizontal']"
            :should-display-add-to-wishlist="false"
          />
        </li>
      </ul>

      <a v-if="hasResults" :href="viewAllLink" class="btn btn--link btn--icon btn--palladium self-start">
        <span class="btn__text">{{ viewAllText }}</span>
        <span class="btn__icon">
          <phx-icon icon="chevron-16" bidirectional />
        </span>
      </a>
    </template>
  </div>
</template>
