import { mapState } from 'vuex';

export default {
  props: {
    submenuType: {
      type: String,
      required: true,
    },
    resultsUrl: {
      type: String,
      required: true,
    },
    suggestedItems: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    ...mapState('search', ['query', 'tabs']),

    hasQuery() {
      return this.query !== '';
    },

    totalItems() {
      return this.tabs[this.submenuType].total;
    },

    items() {
      return this.totalItems > 0 && this.hasQuery ? this.tabs[this.submenuType].data : this.suggestedItems;
    },

    hasItems() {
      return this.items.length > 0;
    },

    hasResults() {
      return this.hasQuery && this.totalItems > 0;
    },

    shouldDisplayNoResults() {
      return this.hasQuery && this.totalItems === 0;
    },

    viewAllLink() {
      return `${this.resultsUrl}?keyword=${this.query}`;
    },

    submenuTitle() {
      return this.$t(`Search.panel.${this.submenuType}.title`);
    },

    noResultsText() {
      return this.$t(`Search.panel.${this.submenuType}.noResults`, { query: this.query });
    },

    viewAllText() {
      return this.$t(`Search.panel.${this.submenuType}.showAll`, { results: this.totalItems });
    },
  },
};
