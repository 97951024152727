import { mapGetters, mapState } from 'vuex';

export default {
  props: {
    frequentlySearchedLinks: {
      type: Array,
      default: () => [],
    },

    resultsUrl: {
      type: String,
      required: true,
    },

    suggestedPages: {
      type: Array,
      default: () => [],
    },

    suggestedProducts: {
      type: Array,
      default: () => [],
    },

    suggestedNews: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    ...mapState('search', ['isLoading', 'query', 'suggestions', 'tabs']),

    ...mapGetters('search', ['totalResults', 'totalSuggestionsResults']),

    hasArticles() {
      return this.hasQuery && this.tabs.articles.total > 0;
    },

    hasFrequentlySearchedLinks() {
      return this.frequentlySearchedLinks.length > 0;
    },

    hasProducts() {
      return this.hasQuery && this.tabs.products.total > 0;
    },

    hasQuery() {
      return this.query !== '';
    },

    hasResults() {
      return this.hasQuery && this.totalResults > 0;
    },

    viewAllLink() {
      return `${this.resultsUrl}?keyword=${this.query}`;
    },

    viewAllText() {
      return this.$t('Search.viewAll', { results: this.totalResults });
    },

    noArticlesAndProductsText() {
      return this.$t('Search.panel.noProductsAndProducts', { query: this.query });
    },

    queryResultText() {
      return this.totalResults === 0
        ? this.$t('Search.panel.noProductsFound', { results: this.totalResults, search: this.query })
        : this.$t('Search.resultsTitle', { results: this.totalResults, search: this.query });
    },
  },
};
