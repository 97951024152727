<script>
import PictureLazy from '@maison/components/common/PictureLazy.vue';
import searchPanelSubmenuMixin from '@maison/mixins/searchPanelSubmenuMixin';
import { PhxIcon } from '@phx-styleguides/jlc';

export default {
  components: {
    PictureLazy,
    PhxIcon,
  },

  mixins: [searchPanelSubmenuMixin],
};
</script>

<template>
  <div class="mega-menu__panel-col mega-menu__panel-col--larger search-submenu-news">
    <section v-if="shouldDisplayNoResults" class="section section--text text-block text-block--centered">
      <h3 class="text-block__title h4">{{ noResultsText }}</h3>
    </section>

    <template v-else-if="hasItems">
      <div class="header-section">
        <h3 class="header-section__title h4">{{ submenuTitle }}</h3>
      </div>
      <ul class="news-card-list search-submenu-news__list">
        <li v-for="article in items.slice(0, 4)" :key="article.id" class="news-card-list__entry">
          <div class="news-card">
            <div class="news-card__picture">
              <div class="picture">
                <picture-lazy
                  v-bind="article.searchImg"
                  img-class="lazyload--fade ls-is-cached lazyloaded"
                  width="80"
                  height="80"
                />
              </div>
            </div>

            <div class="news-card__body">
              <a :href="article.link" class="news-card__link expand-target">
                <span class="news-card__date">{{ article.publicationDate }}</span>
                <h5 class="news-card__title">{{ article.title }}</h5>
              </a>
            </div>
          </div>
        </li>
      </ul>

      <a v-if="hasResults" :href="viewAllLink" class="btn btn--link btn--icon btn--palladium self-start">
        <span class="btn__text">{{ viewAllText }}</span>
        <span class="btn__icon">
          <phx-icon icon="chevron-16" bidirectional />
        </span>
      </a>
    </template>
  </div>
</template>
